.MainClass{
    display: flex;
    justify-content: space-between;
}
.TopSide img{
    width: 150px;
    display: block;
    margin-bottom: 35px;
}
.LeftAside{
    background-color: #fff;
    width: 20%;
    padding: 25px 10px;
    border-radius: 8px;
}
.MiddleSide h5{
    color: #999;
    margin-bottom: 20px;
}
.BottomSide a img, .MiddleSide a img{
    width: 15px;
    display: block;
    margin-right: 8px;
}
.BottomSide a, .MiddleSide ul li a{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #818181;
    padding: 10px;
}
.MiddleSide ul li a:hover{
    color: #64449c;
}
.MiddleSide ul, .BottomSide a {
    margin-left: 10px;
}
.MiddleSide ul li{
    margin-bottom: 6px;
}
.BottomSide a:hover, .MiddleSide ul li:hover{
    background-color: #F8F4FF;
    border-radius: 5px;
}
.MiddleSide ul li.active{
    border-radius: 5px;
    background-color: #F8F4FF;
}
.More{
    padding-top: 10px;
}
.BottomSide{
    margin-top: 60px;
}
.Welcome{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #9A4BCB;
    position: relative;
    padding: 32px 10px;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0px 12px 17px 0px #9a4bcb40
}
.Position {
    position: absolute;
    right: 0;
}
.Position img {
    width: 100%;
    display: block;
    height: 150px;
    width: 150px;
}
.Name{
    margin-right: 15px;
}
.Name h3{
    font-size: 30px;
    font-weight: 600;
}
.Description p{
    width: 60%;
    font-size: 12px;
}
.Examination{
    margin-top: 30px;
}
.Examination .Heading h4{
    font-size: 20px;
    font-weight: 600;
}
.Examination .Heading{
    margin-bottom: 10px;
}
.Examination .Sem{
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    padding: 5px;
}
.Examination .Sem ul{
    display: flex;
    padding: 8px 11px;
    background: #F5F5F5;
    width: max-content;
    border-radius: 6px;
    align-items: center;
}
.Examination .Sem ul li.active{
    background-color: #fff;
}
.Examination .Sem ul li:hover{
    background-color: #fff;
}
.ExamMain{
    border: 1px solid #9999996b;
    border-radius: 8px;
}
.Examination .Sem ul li{
    font-size: 12px;
    color: #999;
    padding: 5px;
    border-radius: 4px;
}
.Examination .Sem ul span{
    font-size: 12px;
    color: #999;
    display: inline-block;
    margin: 0 10px;
}
.ExamTime{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.ExamTime p{
    color: #522F91;
    font-size: 12px;
}
.Completed{
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 7px;
    border-radius: 7px;
}
.Completed img{
    width: 15px;
    display: block;
    margin-right: 5px;
}
.Completed small{
    font-size: 12px;
    color: #999;
}
.LeftSem ul{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}
.LeftSem ul li{
    font-size: 12px;
    color: #999;
}
.LeftSem ul li span{
    color: #000;
    font-weight: 600;
}
.TimeTable table{
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #9999994a;
}
.TimeTable{
    background-color: #fff;
    padding: 15px 10px 10px;
    border-radius: 0 0 10px 10px;
}
.TimeTable h5{
    color: #999;
    font-size: 13px;
    font-weight: 600;
    padding-bottom: 5px;
}
.TimeTable th{
    background: #F5F5F5;
    padding: 10px;
    font-size: 13px;
    font-weight: 600;
}
.TimeTable td{
    font-size: 12px;
    padding: 3px 10px;
    border-bottom: 1px solid #99999940;
    text-align: center;
}
.Complete{
    display: flex;
    align-items: center;
    background-color: #92bf3321;
    padding: 5px 14px;
    border-radius: 5px;
    max-width: max-content;
    margin: 0 0 0 auto;
}
.Complete img{
    width: 12px;
    display: block;
    margin-right: 5px;
}
.Complete h6{
    font-size: 12px;
    color: #a5ca56;
}
.Complete.Attend{
    background-color: #917AFD;
}
.Complete.Attend h6{
    color: #fff;
}
.Result{
    padding: 15px 10px 10px;
}
.Result h4{
    margin-bottom: 10px;
    color: #000;
    font-size: 13px;
    font-weight: 600;
}
.MainResult{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}
.MainResult .LeftCard{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    padding: 0;
}
.MainResult .Card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #0000002b;
    padding: 6px 0px;
}
.MainResult .Card:last-child{
    border: none;
}
.MainResult .LeftCard span{
    color: #999;
    font-size: 12px;
}
.MainResult .LeftCard span.Published{
    color: #000;
}
.Span{
    display: flex;
    align-items: center;
    margin-top: 4px;
}
.MainResult .CheckResult{
    background: #92BF33;
}
.MainResult .CheckResult h6{
    color: #fff;
}
.Profile img{
    width: 150px;
    height: 150px;
    display: block;
    border-radius: 15px;
    margin: 0 auto;
    object-fit: cover;
    border: 4px solid #fff;
}
.ProfileContent h4{
    font-weight: 600;
    font-size: 13px;
    margin-top: 6px;
}
.ProfileContent p{
    margin-top: 4px;
    font-size: 12px;
    color: #999;
}
.ProfileContent span{
    display: inline-block;
    color: #000;
}
.Profile {
    text-align: center;
    background: #d3a58930;
    padding: 15px;
    border-radius: 12px;
    border: 2px solid #d3a5894f;
}
.Upcoming{
    border-radius: 7px;
    background: #fff;
    border: 1px solid #88888840;
    margin-top: 10px;
}
.Upcoming h4{
    text-align: center;
    padding: 9px 5px;
    border-bottom: 1px solid rgb(165 165 165 / 39%);
    font-size: 14px;
    font-weight: 600;
}
.Upcoming .Sub{
    padding: 12px;
    border: 1px solid #99999926;
    border-radius: 7px;
    margin-top: 8px;
}
.Upcoming .Sub.active, .Upcoming .Sub:hover{
    border: 1px solid #917AFD;
}
.Subjects{
    padding: 0 7px 7px;
}
.Upcoming .Sub h5{
    font-weight: 600;
    margin-bottom: 6px;
}
.Upcoming .Sub p{
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #999;
}
.Upcoming .Sub p img{
    width: 12px;
    display: block;
    margin-right: 6px;
}
.Upcoming .Sub button{
    background: #917AFD;
    border-radius: 4px;
    padding: 6px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}
.Upcoming .Sub .Attended{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.EmptyExam{
    background: #f3f3f3;
    padding: 25px;
    text-align: center;
    border-radius: 8px;
    margin-top: 8px;
}
.EmptyExam img{
    display: block;
    width: 80px;
    margin: 0 auto 10px;
}
.EmptyExam p{
    color: #999;
    font-size: 13px;
}
.EmptyUpcoming {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
}
.EmptyUpcoming p{
    font-size: 13px;
}
.QuestionContainer{
    padding: 50px;
    background-color: #fff;
    margin-top: 15px;
    border-radius: 13px;
}
.QuestionContainer .QuestionList{
    width: 60%;
    margin: 0 auto;
}
.QuestionContainer .QuestionList .CountQ p{
    width: 100%;
    height: 5px;
    background: #d9d9d9;
    border-radius: 20px;
    margin: 5px 0 25px;
}
.QuestionContainer .QuestionList .CountQ p span{
    width: 20%;
    height: 5px;
    background: #9179f7;
    display: inherit;
    border-radius: 20px;
}