* {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	background: transparent;
	font-weight: normal;
	font-style: normal;
	text-decoration: none;
	outline: none;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
img {
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}
*::-moz-selection {
}
*::selection {
}
*::-webkit-scrollbar {
	width: 0;
}
*::-webkit-scrollbar-thumb:hover {
	background: #b7b7b7;
}
ol,
ul {
	list-style: none;
}
blockquote {
	quotes: none;
}
blockquote:before,
blockquote:after {
	content: "";
	content: none;
}
del {
	text-decoration: line-through;
}
body {
	font-size: 1.0625rem;
	color: #333;
	background: #f9fbfd;
	overflow-x: hidden;
	min-width: 20rem;
	position: relative;
	font-family: "inter-regular";
}
input,
textarea,
select,
pre {
	font-family: "inter-regular";
	background: unset;
	border: unset;
}
textarea,
input[type="number"]::-webkit-inner-spin-button,
input[type="text"],
input[type="button"],
input[type="submit"] {
	-webkit-appearance: none;
	border-radius: 0;
}

*:focus {
	outline: none;
}
a {
	color: #333;
}
.submit:hover {
	filter: alpha(opacity=85);
	-moz-opacity: 0.85;
	-khtml-opacity: 0.85;
	opacity: 0.85;
}
.thumb {
	display: block;
	width: 100%;
}
.thumb img {
	display: block;
	width: 100%;
}
p {
	line-height: 1.6em;
	font-size: 1rem;
	color: #fff;
}
br.clear {
	clear: both;
	line-height: 0;
}

button:hover {
	/* box-shadow: rgba(0, 0, 0, 0.25) 2.4px 2.4px 3.2px; */
	/* transform: translatey(-0.5em);
    transform: translatex(-0.15em); */
	/* transition: 0.25s; */
}
.slick-arrow {
	display: none !important;
}
/* @font-face {
    font-family: "poppinsbold";
    src: url("../fonts/poppins-bold-webfont.woff2") format("woff2"),
        url("../fonts/poppins-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "poppinsmedium";
    src: url("../fonts/poppins-medium-webfont.woff2") format("woff2"),
        url("../fonts/poppins-medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "poppinsregular";
    src: url("../fonts/poppins-regular-webfont.woff2") format("woff2"),
        url("../fonts/poppins-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "poppinssemibold";
    src: url("../fonts/poppins-semibold-webfont.woff2") format("woff2"),
        url("../fonts/poppins-semibold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "product_sansbold";
    src: url("../fonts/product_sans_bold-webfont.woff") format("woff2"),
        url("../fonts/product_sans_bold-webfont.woff2") format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "product_sansregular";
    src: url("../fonts/product_sans_regular-webfont.woff2") format("woff2"),
        url("../fonts/product_sans_regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
} */
@font-face {
	font-family: "poppinsregular";
	src: url("../fonts/poppins-regular-webfont.woff2") format("woff2"),
		url("../fonts/poppins-regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "inter-regular";
	src: 
    /* url("../fonts/inter/Inter-Regular.woff2") format("woff2"), */ url("../fonts/inter/Inter-Regular.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "inter-medium";
	src: 
    /* url("../fonts/inter/Inter-Medium.woff2") format("woff2"), */ url("../fonts/inter/Inter-Medium.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "inter-semibold";
	src: 
    /* url("../fonts/inter/Inter-SemiBold.woff2") format("woff2"), */ url("../fonts/inter/Inter-SemiBold.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "inter-bold";
	src: 
    /* url("../fonts/inter/Inter-Bold.woff2") format("woff2"), */ url("../fonts/inter/Inter-Bold.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "inter-light";
	src: 
    /* url("../fonts/inter/Inter-Light.woff2") format("woff2"), */ url("../fonts/inter/Inter-Light.woff")
		format("woff");
	font-weight: normal;
	font-style: normal;
}

/* Animations */
@keyframes growDown {
	0% {
		transform: scaleY(0);
	}

	50% {
		transform: scaleY(1.2);
		transform: translateY(1%);
		backface-visibility: hidden;
	}
	100% {
		transform: scaleY(1);
	}
}
@keyframes slideInBottom {
	0% {
		transform: translateY(0%);
	}
	50% {
		transform: translateY(5%);
	}
	100% {
		transform: translateY(0%);
	}
}
@keyframes slideInBottom2 {
	0% {
		transform: translateY(0%);
	}
	50% {
		transform: translateY(20%);
		transform: scaleY(1.1);
		backface-visibility: hidden;
	}
	100% {
		transform: translateY(0%);
		transform: scaleY(1);
	}
}
@keyframes rotateMenu {
	0% {
		transform: rotateX(-90deg);
	}
	70% {
		transform: rotateX(20deg);
	}
	100% {
		transform: rotateX(0deg);
	}
}
@keyframes wiggle {
	0%,
	7% {
		transform: rotateZ(0);
	}
	15% {
		transform: rotateZ(-15deg);
	}
	20% {
		transform: rotateZ(10deg);
	}
	25% {
		transform: rotateZ(-10deg);
	}
	30% {
		transform: rotateZ(6deg);
	}
	35% {
		transform: rotateZ(-4deg);
	}
	40%,
	100% {
		transform: rotateZ(0);
	}
}
@keyframes blinker {
	50% {
		opacity: 0;
	}
}
/* vedio playe */
/* .media-container .ytp-chrome-top {
    background: #f00 !important;
    padding: 25px;
    color: #f00 !important;
}
.media-container {
}
.media-container .ytp-title,
.ytp.title-text,
.ytp-chrome-top-buttons,
.ytp-title-enable-channel-logo .ytp-title-channel,
.html5-video-player a {
    display: none !important;
}
.html5-video-player a:hover {
    display: none !important;
}
.media-container .ytp-chrome-top {
    display: none !important;
} */

/* Calendar */
.react-calendar {
	width: 100%;
	max-width: 100%;
	background: white;
	/* border: 1px solid #a0a096; */
	border-radius: 15px;
	font-family: "inter-regular", Arial, Helvetica, sans-serif;
	line-height: 1.125em;
}
.react-calendar--doubleView {
	width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
	display: flex;
	margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
	width: 50%;
	margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.react-calendar button {
	margin: 0;
	border: 0;
	outline: none;
}
.react-calendar button:enabled:hover {
	cursor: pointer;
}
.react-calendar__navigation {
	display: flex;
	height: 44px;
	margin-bottom: 1em;
}
.react-calendar__navigation button {
	min-width: 44px;
	background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
	background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
	background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.75em;
	color: #999 !important;
}
.react-calendar__month-view__weekdays__weekday {
	padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75em;
	font-weight: bold;
	padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
	color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
	color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
	padding: 2em 0.5em;
}
.react-calendar__tile {
	max-width: 100%;
	text-align: center;
	padding: 0.75em 0.5em;
	background: none;
	border-radius: 6px;
}
.react-calendar__tile:disabled {
	background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: #f5f5f5;
	border-radius: 6px;
}
.react-calendar__tile--now {
	background: #b2a1fd;
	color: #fff;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background: #917afd;
	border-radius: 6px;
}
.react-calendar__tile--hasActive {
	background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
	background: #a9d4ff;
	border-radius: 6px;
}
.react-calendar__tile--active {
	background: #3f9fff;
	border-radius: 6px;
	color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: #1087ff;
	border-radius: 6px;
}
.react-calendar--selectRange .react-calendar__tile--hover {
	background-color: #e6e6e6;
	border-radius: 6px;
}
.react-calendar__navigation__label__labelText {
	font-size: 14px;
	font-family: "inter-medium";
}

/* Animation property */
button.wiggle-animation:hover {
	animation: wiggle 2.5s linear infinite;
}

.pdf-lms .react-pdf__Page__canvas {
	margin: 0 auto;
	width: 80% !important;
	height: 100% !important;
}
.pdf-lms .react-pdf__Page__svg {
	width: 95%;
	height: 100% !important;
	margin: 0 auto;
	overflow: scroll !important;
}
.pdf-lms .react-pdf__Page__svg svg:svg {
	width: 100%;
}

/* vimeo */
#player .logo {
	display: none !important;
}
#media-container .player .vp-sidedock {
	display: none !important;
}

/* Styles for CKEditor */
.ck strong {
	font-weight: bold !important;
}

.ck i {
	font-style: italic !important;
}
.ck i strong {
	font-weight: bold !important;
	font-style: italic !important;
}
.ck i strong u {
	font-weight: bold !important;
	font-style: italic !important;
	text-decoration: underline;
}
.ck strong u {
	font-weight: bold !important;
	text-decoration: underline;
}
.ck i u {
	font-style: italic !important;
	text-decoration: underline;
}
.ck u {
	text-decoration: underline;
}
.ck.ck-editor__editable_inline {
	border: 1px solid #adadad !important;
	min-height: 150px;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
	box-shadow: none !important;
	border: 1px solid #2d60ff !important;
}

.ck ul {
	list-style: disc;
	padding: 0 15px;
}
.ck ol {
	list-style: decimal;
	padding: 0 15px;
}

.ck p {
	line-height: 1.3;
	color: #313131;
	font-size: 0.85rem;
	letter-spacing: 0.04em;
	opacity: 0.9;
}
.ck h2 {
	line-height: 1.6 !important;
	font-size: 1.2rem !important;
	letter-spacing: 0.04em;
	font-weight: 600;
	margin: 3px 0;
}
.ck h3 {
	line-height: 1.45;
	font-size: 1.05rem;
	font-weight: 600;
	letter-spacing: 0.04em;
	margin: 2px 0 3px 0;
}
.ck h4 {
	line-height: 1.43;
	line-height: 1.45;
	font-size: 1.05rem;
	font-weight: 500;
	letter-spacing: 0.04em;
	margin: 2px 0 3px 0;
}
.ck.ck-toolbar > .ck-toolbar__items button {
	margin-right: 10px !important;
}
.fQzTbI {
	height: 50px !important;
}
.JcExk {
	height: 50px !important;
}
.dsHfAg {
	padding: 4px 18px !important;
}
